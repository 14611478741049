import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { MatFormFieldAppearance, MatFormFieldModule, SubscriptSizing } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'sn-file-upload',
    templateUrl: './file-upload.component.html',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule
    ],
})
export class FileUploadComponent {
    file: File | null;
    /**
     * A signal-based input indicating whether a file is required.
     * Consumers pass in a boolean value.
     */
    required = input<boolean>(false);

    /**
     * A signal-based input for specifying accepted file types.
     * Example: '.pdf,.doc,.docx' or 'image/*'
     */
    accept = input<string>('');

    /**
     * A signal-based input for controlling the form field appearance.
     * Options are 'fill' | 'outline'
     */
    appearance = input<MatFormFieldAppearance>('fill');

    /**
     * A signal-based input for controlling the subscript sizing of the form field.
     * Options are 'fixed' | 'dynamic'
     */
    subscriptSizing = input<SubscriptSizing>('dynamic');

    /**
     * A signal-based input for the hint text displayed below the form field.
     * Example: 'Only PDF files are allowed'
     */
    hint = input<string>('');

    /**
     * A signal-based output that holds the currently selected File (or null).
     * Consumers can subscribe to this signal to be notified of file changes.
     */
    fileChanged = output<File | null>();

    /**
     * Returns true if the field is required but no file is selected.
     */
    get isInvalid(): boolean {
        return this.required() && !this.file;
    }

    /**
     * Handles the file input change event and updates the fileChanged signal.
     */
    onFileSelected(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.file =
            inputElement.files && inputElement.files.length > 0
                ? inputElement.files[0]
                : null;
        this.fileChanged.emit(this.file);
    }
}
