<mat-form-field class="w-full" [appearance]="appearance()" [class.mat-form-field-invalid]="isInvalid" [subscriptSizing]="subscriptSizing()">
    <mat-label>Choose a File</mat-label>

    <!-- Display the selected file name using the output signal -->
    <input matInput [value]="file?.name || ''" readonly />

    <!-- Suffix icon to trigger file selection -->
    <button matSuffix (click)="fileInput.click()" matTooltip="Select File">
        <mat-icon svgIcon="heroicons_outline:paper-clip" class="scale-75 mr-1"></mat-icon>
    </button>

    <!-- Hidden file input element -->
    <input type="file" #fileInput hidden (change)="onFileSelected($event)" [attr.accept]="accept()" />

    <!-- Using new @if syntax for conditional error message -->
    @if (isInvalid) {
        <mat-error>File is required</mat-error>
    }

    <!-- Display hint text if provided -->
    @if (hint()) {
        <mat-hint>{{hint()}}</mat-hint>
    }
</mat-form-field>